<template lang="html">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="row form-group">

            <div class="col-2">
              <label class="required">Tiêu đề</label>
            </div>

            <div class="col-10">

              <div class="row">

                <div class="col-6">
                  <el-input
                    placeholder="Tiếng Việt"
                    v-model="form.vi_title"
                    :class="errors.has('vi_title')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="vi_title"
                    data-vv-as="Tiêu đề"
                  ></el-input>
                  <span class="text-danger" v-if="errors.has('vi_title')">{{ errors.first('vi_title') }}</span>
                </div>

                <div class="col-6">
                  <el-input
                    placeholder="Tiếng Anh"
                    v-model="form.en_title"
                    :class="errors.has('en_title')?'border-danger':''"
                    v-validate="'required'"
                    data-vv-name="en_title"
                    data-vv-as="Tiêu đề"
                  ></el-input>
                  <span class="text-danger" v-if="errors.has('en_title')">{{ errors.first('en_title') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group">

            <div class="col-2">
              <label class="required">Giá trị</label>
            </div>

            <div class="col-10">

              <div class="row">
                <div class="col-9">
                  <el-input
                    type="number"
                    placeholder="Giá trị"
                    v-model="form.distance"
                    :class="errors.has('distance')?'border-danger':''"
                    v-validate="'required|min_value:0'"
                    data-vv-name="distance"
                    data-vv-as="Giá trị">
                  </el-input>
                  <span class="text-danger" v-if="errors.has('distance')">{{ errors.first('distance') }}</span>
                </div>
                <div class="col-3">
                  <el-select v-model="form.unit" placeholder="Đơn vị" class="full-width">
                    <el-option
                      v-for="item in unitOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="updateCatalog">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { MessageBox, Message, Input, Button, Select, Option } from 'element-ui';
  import MyImage from 'src/components/UIComponents/Image'
  import Editor from '@tinymce/tinymce-vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      Message,
      ElSelect: Select,
      ElOption: Option
    },

    data() {
      return {
        unitOptions: [{
          value: 'km',
          label: 'km'
        }, {
          value: 'step',
          label: 'bước'
        },
        {
          value: 'times',
          label: 'cái'
        }]
      }
    },

    computed: {
      form: {
        get() {
          return this.$store.state.catalogDetail;
        },
        set(value) {}
      },
    },

    mounted() {
      let id = this.$route.params.id;
      this.$store.dispatch('fetchCatalogDetail', id);
      this.$store.dispatch('setPageTitle', 'Chỉnh sửa cự ly');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Cập nhật',
        type: 'primary',
        icon: '',
        callback: this.updateCatalog
      }]);
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllCatalogs'});
      },
      updateCatalog() {
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            let data = self.form;
            self.$store.dispatch('updateCatalog', data);
            Message({
              message: 'Cập nhật thành công',
              type: 'success'
            });
          }
        })
      },
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
      this.$store.state.catalogDetail = [];
    }
  }
</script>
